import React from "react"
import { graphql } from "gatsby"
import SEO from "components/seo"

export default ({ data }) => {
    const {
        title,
        excerpt,
        content
    } = data.siteFields.page

    return (
        <>
            <SEO title={title} description={excerpt} />
            <div className="text-page container">
                <h1>
                    { title }
                </h1>
                <div className="copy" dangerouslySetInnerHTML={{ __html: content }} />
            </div>
        </>
    )
}

export const query = graphql`
  query($databaseId: ID!) {
    siteFields {
      page(id: $databaseId, idType: DATABASE_ID) {
        databaseId
        title
        slug
        date
        content(format: RENDERED)
      }
    }
  }
`
